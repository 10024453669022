import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "count" */ '@/views/home/index'),
        meta: {
            title: '司机帮'
        }
    },
    {
        path: '/privacyPolicy',
        name: 'privacyPolicy',
        component: () => import(/* webpackChunkName: "count" */ '@/views/privacyPolicy/index'),
        meta: {
            title: '隐私协议'
        }
    },
    {
        path: '/userProtocol',
        name: 'userProtocol',
        component: () => import(/* webpackChunkName: "count" */ '@/views/userProtocol/index'),
        meta: {
            title: '用户协议'
        }
    },
]

const router = new VueRouter({
    // mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

export default router
