<template>
  <div>
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  methods: {
    handleTip () {
      window.open('https://beian.miit.gov.cn/')
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.mian1 {
  width: 295px;
  height: 50px;
  margin-left: 150px;
}
.mian2 {
  width: 1900px;
  height: 884px;
}
.mian3 {
  width: 1016px;
  height: 692px;
  align-self: center;
}
.content {
  display: flex;
  flex-direction: column;
}
.marginBottom {
  margin-bottom: 20px;
}
.tip {
  margin-top: 50px;
  font-size: 14px;
  color: #6A6A6A;
  cursor: pointer;
}
</style>
